import { Component, Vue } from 'vue-property-decorator'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'

//molds
import { Form } from '@/components/wrappers/molds/Form';
import { TableOptions, WolfApiData } from '@/components/wrappers/molds/TableOptions';
import { PermissionProfile } from '@/store/modules/permission/permission';

//classes
import { Alert } from '@/services/notification';

//Vuex
const namespace = 'permission';
import { Action, Getter } from 'vuex-class';

//wrappers
import {
	WForm, 
	Container, 
	Box, 
  WButton,
  WHr,
	WSubtitle,
	WTable,
	Loader,
	WColumn,
	WColumns,
	SizedBox,
} from '@/components/wrappers';

@Component({
	components: {
		WHr,
		Box,
		WForm,
		WButton,
		WSubtitle,
		Container,
		PrivateContent,
		WTable,
		Loader,
		WColumn,
		WColumns,
		SizedBox
	}
})
export default class SearchProfilePerm extends Vue { 
	
	@Action('fetchPermissionProfiles', {namespace}) fetchPermissionProfiles: any 
	@Getter('permissionProfiles', {namespace}) permissionProfiles!: WolfApiData 
	@Getter('loader', { namespace }) isLoading!: boolean;
	@Getter('error', {namespace}) error!: any 
	
	perPage = 10
	isSearch = false
	spaceTable = false
	paramQuery!: string
	result!: WolfApiData

	alert = new Alert(this.$store)

	mounted(): void {
		document.title = "Pesquisar Perfil de Permissão"
	}
	
	showSpaceTable(): void{
		this.spaceTable = true
	}

	async dispatchForm(paramQuery: string): Promise<void> {
		this.paramQuery = paramQuery

		await this.fetchPermissionProfiles(`${paramQuery}&_limit=${this.perPage}`)
		
		if(this.error){
			this.alert.apiMessage("Não foi possível pesquisar perfil de permissão.", this.error, "danger")
		}else{
			if(this.permissionProfiles.list.length == 0){
				this.alert.warning("Nenhum perfil encontrado")
			}else{
				window.scroll({
					top: 500,
					behavior: 'smooth'
				});
			}
			this.isSearch = true
		}
	}

	changePage(page: number): void {
		this.dispatchForm(`${this.paramQuery}&_page=${page}`)
	}

	tableSelected(permissionProfile: PermissionProfile){
		this.$router.push({
			path: `/perm-profile/${permissionProfile.idPermission}`
		})
	}

	getStatusName(status: string): string {
		switch (status) {
			case "0":
				return 'Desativado'
			case "1":
				return 'Ativo'
			case "2":
			default:
				return status
		}
	}

	get convertStatus(): WolfApiData {
		const list = []
		let permProfile!: PermissionProfile
		
		for (let i = 0; i < this.permissionProfiles.list.length; i++) {
			permProfile = this.permissionProfiles.list[i]
			permProfile.status = this.getStatusName(permProfile.status)
			list.push(permProfile)
		}

		this.result = this.permissionProfiles
		this.result.list = list;

		return this.result
	}

	get	table(): TableOptions {
		return {	
			paginated: true,
			perPage: this.perPage,
			wolfApiData: this.convertStatus,
			columns: [
				{
					field: 'idPermission',
					label: 'ID',
					numeric: true,
					sortable: true
				},
				{
					field: 'name',
					label: 'Nome',
					sortable: true
				},
				{
					field: 'status',
					label: 'Status',
					sortable: true
				}
			]
		}
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			paramQuery: true,
			grid: [
				[
					{
						fieldName: "name",
						type: "text",
						label: "Nome",
						placeholder:"Nome",
						maxlength: 50,
					},
					// {
					// 	fieldName: "description",
					// 	type: "text",
					// 	label: "Descrição",
					// 	placeholder:"Descrição",
					// 	maxlength: 200,
					// },
				],
				[
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: "1",
						options:[
							{
								label: "Desativado",
								value: "0"
							},
							{
								label: "Ativo",
								value: "1"
							},
							// {
							// 	label: "Todos",
							// 	value: ""
							// },
						]
					},
				],
			]
		}
	}
}
